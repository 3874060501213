@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");
@import url("bootstrap/dist/css/bootstrap.min.css");

* {
  font-family: "DM Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef1f0;
  /* overflow-y: hidden; */
}
.ant-modal .ant-modal-close {
  display: none;
}
.ant-upload-wrapper .ant-upload-drag {
  position: relative;
  width: 140%;
  margin-left: -20%;
  height: 100%;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  background: rgba(243, 245, 248, 0.8);
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #1d6065;
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #cdd4e3;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
  color: #fff;
}
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  color: #fff;
  background-color: #cdd4e3;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.2s, border-color 0.2s;
}
.ant-card-hoverable:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.clicked {
  border: 1px solid #3ac1cb; /* Change to your primary color */
  background-color: #3ac1cb0f;
  /* background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)"; */
}
.ant-layout .ant-layout-sider {
  background: linear-gradient(90deg, #14222f 0%, #1d6065 100%) !important;
}
.ant-menu-light,
.ant-menu-light > .ant-menu {
  background: linear-gradient(90deg, #14222f 0%, #1d6065 100%) !important;
  color: #ffffff !important;
}
.ant-menu-light .ant-menu-item-selected {
  background-color: #ffffff17 !important;
  border-radius: 200px !important;
  /* color: #ffffff !important; */
}

.ant-menu-light .ant-menu-item:hover {
  background-color: #ffffff17 !important;
  border-radius: 200px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
}
.ant-menu .ant-menu-item .ant-menu-item-icon {
  color: #fff;
}
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon > * {
  color: #fff;
}
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  color: #fff;
}
.ant-menu .ant-menu-submenu-arrow::before {
  color: #fff;
}
.ant-menu .ant-menu-submenu-arrow::after {
  color: #fff;
}
/* .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #1b1b1b;
} */
.ant-menu-light .ant-menu-submenu > .ant-menu-submenu-title {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #1b1b1b;
}
.ant-menu-inline.ant-menu-root
  .ant-menu-item-selected
  > .ant-menu-title-content {
  color: #ffffff;
}
.ant-dropdown .ant-dropdown-menu {
  background-color: #eef1f0;
}
/* .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover {
  background-color: #eef1f0;
} */
.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover {
  background-color: #ffffff;
}
.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):focus {
  background-color: #3ac1cb;
  color: #ffffff;
}
/* .ant-tooltip .ant-tooltip-content {
  display: none !important;
} */
.ant-tooltip .ant-tooltip-inner {
  display: none !important;
}
.ant-tooltip-placement-top > .ant-tooltip-arrow {
  display: none !important;
}
/* .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
  background-color: #FFFFFF;
  padding: 10;
} */
.ant-modal {
  padding: 20px 15px;
  /* background-color: pink; */
}

.ant-modal .ant-modal-content {
  padding: 20px 10px;
  background-color: #f3f5f8;
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #3ac1cb;
}

.ant-dropdown .ant-dropdown-menu {
  background-color: #fff !important;
}

.user_profile h6 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1b1b1b;
}

.header_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header_wrapper .search {
  margin-right: 5%;
  margin-left: 1.5%;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  transition: border-color 0.3s;
  outline: none;
  border-radius: 200px;
  width: 30%;
}
.search:hover,
.search:focus,
.search:active {
  border-color: #3ac1cb;
}

.header_wrapper .search_icon {
  font-size: 18px;
  color: #33363f5c;
}
.header_wrapper .log_out {
  color: #3a3a5d;
  font-size: 22px;
}
.header_wrapper .question {
  color: #3a3a5d;
  font-size: 22px;
}
.header_wrapper .user {
  color: #3a3a5d;
  font-size: 32px;
}
.ant-alert-success {
  background-color: #1a2f3c17;
  border: 1px solid #fff;
}
.ant-alert {
  padding: 13px 12px;
}
.custom-alert .anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  padding: 5px;
  -moz-osx-font-smoothing: grayscale;
}
.facility_wrapper {
  border-radius: 16px;
  background-color: transparent;
  border: none;
}
.facility_wrapper:hover {
  /* background-color: #3ac1cb; */
  transition: all 0.4s ease-in-out;
}
.facility_wrapper:hover h2 {
  /* color: red; */
  transition: all 0.1s ease-in-out;
}
.facility_wrapper h2 {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  /* line-height: 27px; */
  letter-spacing: 0em;
  /* margin: 10px 0 0 0; */
  color: #06152b;
}
.ant-card .ant-card-body {
  padding: 0px;
  border-radius: 0 0 8px 8px;
}

.search_wrapper {
  padding: 10px 11px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #1b1b1b;
  width: 100%;
  /* background-color: #FFFFFF; */
  border-radius: 10px;
}
.search_wrapper::placeholder {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  color: #1b1b1b;
  opacity: 60%;
}
.search_wrapper:focus {
  box-shadow: none;
}

.search_button:hover {
  background-color: #3ac1cb !important;
  border: none;
}
/* .search_button svg{
  width: 21px;
  height: 21px;
  color: #fff;
} */

.content_wrapper {
  padding: 15px;
  height: 144px;
  border-radius: 8px;
}
.content_wrapper h4 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #06152b;
}
.content_wrapper p {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}
/* modal start */
.modal_content {
  margin-left: 12%;
}
.content_modal {
  overflow-y: scroll;
  height: 450px;
  margin: 30px 0 35px;
  padding-left: 20px;
  padding-right: 10px;
}
.content_modal p {
  margin-right: 22px;
}
.content_modal::-webkit-scrollbar {
  width: 8px;
}
.content_modal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #0000001a;
}
.content_modal h4 {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #000;
}
.content_modal p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000;
}

.search_modal {
  overflow-y: scroll;
  height: 550px;
  margin: 30px 0 35px;
  padding-left: 10px;
  padding-right: 10px;
}
.search_modal::-webkit-scrollbar {
  width: 8px;
}
.search_modal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #0000001a;
}
.dailog_modal {
  overflow-y: scroll;
  height: 520px;
  margin: 20px 0 35px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f3f5f8;
}
.dailog_modal::-webkit-scrollbar {
  width: 8px;
}
.dailog_modal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #0000001a;
}
/* .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #3ac1cb;
} */
.custom-dropdown .ant-select-dropdown {
  max-height: 200px; /* Set the desired height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}
.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #3ac1cb;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  border-radius: inherit;
  background-color: #fff;
}
.ant-input-group
  .ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  color: #3ac1cb;
}
.ant-select .ant-select-outlined {
  border-color: #3ac1cb;
}

.ant-select .ant-select-selection-item {
  flex: none;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3ac1cb;
  color: #ffffff;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: #dcf0ec;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #3ac1cb;
  border-color: #ffffff;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #3ac1cb;
  border-color: transparent;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #3ac1cb;
}
.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #ff4d4f;
  background: #f3f5f5;
}
.ant-select-selection-item {
  background-image: none !important;
}
.ant-input-outlined:focus-within {
  /* border-color: red; */
  border-color: #1d6065;
}
.ant-input:hover {
  /* border-color: red; */
  border-color: #1d6065;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 0px !important;
  height: 100% !important;
  padding: 0 0 0 0px !important;
}
.react-tel-input .flag-dropdown {
  background-color: #f5f5f5;
  border: none !important;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  margin-left: 10px;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #1d6065 !important;
  box-shadow: none !important;
}
.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #1d6065;
}
.ant-select-focused:where(
    .css-dev-only-do-not-override-1uweeqc
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #1d6065;
}
.ant-input-outlined:hover {
  border-color: #1d6065;
}
/* .ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  margin-top: -20px;
} */
:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item-active {
  font-weight: 600;
  background-color: #192e3c;
  border-color: none;
  border: 1px solid transparent;
  border-radius: 20px;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item-active
  a {
  color: #fff;
  background-color: #192e3c;
  border-color: none;
  border: 1px solid transparent;
  border-radius: 20px;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-inline-end: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 20px;
  outline: 0;
  cursor: pointer;
  user-select: none;
  color: #2c2f46;
  font-size: 14px;
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item:hover {
  color: #fff;
}
.history-button {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  border-color: white;
  font-weight: 500;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  transition: background-color 0.3s ease;
}
.history-button .dots-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  cursor: pointer;
}

.history-button:hover .dots-icon {
  display: inline-block;
}

.sticky-footer {
  position: sticky;
  bottom: 10px;
  /* margin-bottom: 10px; */
  z-index: 1000;
}
/* modal end */

/* response page css start */
/* {
  background-color: transparent;
  border: 1px solid #000;
} */
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
  border-width: 2px !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  /* border-color: #000 !important; */
  border-width: 2px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  color: #1b1b1b !important;
}
.ant-input-group > .ant-input:first-child {
  background-color: white;
  transition: border-color 0.3s;
  outline: none;
  border-radius: 200px;
  height: 42px;
  border: none;
}
.ant-input-group > .ant-input:first-child:focus {
  border-color: #3ac1cb;
  border-width: 2px;
}
.responce_search textarea,
.responce_search textarea:hover,
.responce_search textarea:focus-within,
.responce_search textarea:focus {
  background-color: white;
  box-shadow: none;
}
.responce_search textarea::placeholder {
  color: #1b1b1b;
}

.responce_wrapper {
  position: relative;
}
.responce_wrapper h4 {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #548235;
  position: absolute;
  left: 18;
  top: -18px;
  padding: 6px 13px;
  background: #fff;
  border-radius: 20px;
}
.responce_wrapper div {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000;
}

.content-icon {
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 6px;
  font-size: 18px;
  line-height: 0;
}
.content-icon a,
.content-icon a:hover {
  font-size: 18px;
  color: #7f7f7f;
  line-height: 0;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar {
  width: 8px !important;
}
.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a1a1a1 !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 16px !important;
}
.css-14lo706 {
  font-size: 13px !important;
}
.response_scroll {
  overflow-y: scroll;
  height: "auto";
}
.response_scroll::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a1a1a1 !important;
}
.response_scroll::-webkit-scrollbar {
  width: 8px !important;
}
.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.react-phone-input-2:focus {
  border-color: #1677ff; /* Desired border color */
  box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2); /* Desired box shadow */
}
.Card_grid {
  font-weight: 600;
  opacity: "10%";
}
.custom-spin .ant-spin-dot-item {
  /* style affects custom-spin ant component alone */
  background-color: #3ac1cb !important;
}

.slide-left-enter {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.slide-left-leave {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1.2s ease-in-out, transform 1.2s ease-in-out;
}

.ant-typography p {
  margin-bottom: 0 !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.menu-button {
  display: none;
}

.ant-btn:hover .menu-button {
  display: block;
}
.ant-table-wrapper .ant-table-pagination-right {
  justify-content: center;
}

.ant-drawer .ant-drawer-body {
  width: 100% !important;
}
/* .iONEXT {
  width: 100% !important;
} */

.ant-table-thead {
  background-color: white !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-menu-light .ant-menu-item,
:where(.css-dev-only-do-not-override-1uweeqc).ant-menu-light
  > .ant-menu
  .ant-menu-item,
:where(.css-dev-only-do-not-override-1uweeqc).ant-menu-light
  .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-1uweeqc).ant-menu-light
  > .ant-menu
  .ant-menu-submenu-title {
  color: white !important;
}
.screen-lang-select .ant-select .ant-select-selection-item {
  flex: none;
  color: white;
}

/* response page css end */

/* responsive start */
@media (max-width: 600px) {
  .card_img {
    height: 40px;
  }
  .facility_wrapper h2 {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .search_wrapper {
    flex-direction: column !important;
    align-items: center;
    font-size: 14px;
  }
  .search_wrapper .search_button {
    margin-top: 15px;
  }
  .search_wrapper::placeholder {
    font-size: 14px;
  }
  .modal_content {
    padding: 10px;
  }
  .responce_search {
    flex-direction: column !important;
    align-items: center !important;
  }
  .responce_search textarea:focus {
    border-color: #0e0e0e;
    outline: none;
  }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0 !important;
  }
  .search_button {
    margin-top: 15px;
  }
  .response_scroll {
    overflow-y: scroll;
    height: 400px;
  }
}
/* responsive end */
